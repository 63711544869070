<template>
  <div>
    <div style="display: flex;align-items: flex-start;justify-content:space-between;margin-top: 1em" v-for="(p, i) in list" :key="i">
      <div style="display: flex;align-items: flex-start;">
        <div style="display: flex;">
          {{p.relationPrefix}}<span :style="{marginLeft: '0.5em',width: '5em', ...underlineStyle}">{{p.relationSuffix}}</span>*
        </div>
        <div style="flex: 1;display: flex;margin-left: 0.5em;">
          姓名：<span :style="{width: '4em', ...underlineStyle}">{{p.name}}</span>*
        </div>
      </div>
      <div style="flex: 1;min-width: 0;margin-left: 0.5em;">
        <div style="display: flex">
          生：
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{p.birth ? p.birth.lunarYear : ""}}</span>
          年
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{p.birth ? p.birth.lunarMonth : ""}}</span>
          月
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{p.birth ? Math.round(p.birth.lunarDate) : ""}}</span>
          日
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}"></span>
          时.
        </div>
        <div style="display: flex;margin-top: 1em;">
          卒：
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{p.die ? p.die.lunarYear : ""}}</span>
          年
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{p.die ? p.die.lunarMonth : ""}}</span>
          月
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}">{{p.die ? Math.round(p.die.lunarDate) : ""}}</span>
          日
          <span :style="{flex: 1, minWidth: 0, ...underlineStyle, textAlign: 'center'}"></span>
          时.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="tsx">

    export default {
        name: "BuddhismPersonList",
        props: {
            underlineStyle: {
                type: Object,
                default() {
                    return {}
                }
            },
            list: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>
