<template>
  <div class="content md no-back flex-box vertical">
    <div class="piece-box niche-info">
      <div class="niche-info-img"></div>
      <detail-list label-width="6em" class="niche-info-detail" :list="infoList"></detail-list>
    </div>
    <div class="niche-range-query">
      <a-form-model layout="inline">
        <a-form-model-item label="下单时间">
          <a-range-picker
            v-model="form.range"
            @change="getNicheOrder"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            style="width:240px"
          />
        </a-form-model-item>
        <a-button type="primary" @click="getNicheOrder">查询</a-button>
        <a-button @click="back" style="margin-left: 16px;">返回龛位总览</a-button>
      </a-form-model>
    </div>
    <div class="piece-box niche-order-area">
      <div class="niche-order-list" v-if="list && list.length > 0">
        <div class="niche-order-item" v-for="item in list" :key="item.id">
          <detail-list label-width="6em" :list="item.infoList"></detail-list>
        </div>
      </div>
      <a-empty description="暂无订单" :image="emptyImage" v-else-if="list" />
      <a-pagination
        v-bind="pagination"
        @change="handlePageChange"
        style="margin-top: 10px;text-align: right"
        v-show="pagination.total > 0"
      />
    </div>
  </div>
</template>

<script>
import detailList from "../../layouts/DetailList";
import {getLightAreaText, dealNicheOrder, dealNiche} from "./light";
import { Empty } from "ant-design-vue";

export default {
  name: "NicheDetail",
  components: {
    detailList,
  },
  data() {
    return {
      disabled: false,
      form: {},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: null,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      infoList: [],
    };
  },
  computed: {
    area() {
      return getLightAreaText(this.district);
    },
    id() {
      return this.$route.params.id;
    },
    district() {
      return this.$route.params.district;
    },
    // infoList() {
    //   return [
    //     { label: "所在区域", key: "district", value: this.area },
    //     { label: "是否开放", key: "disabled", value: this.disabled ? "否" : "是" },
    //     { label: "龛位编号", key: "num", value: this.num },
    //   ];
    // },
  },
  created() {
    // this.getDisabledRecord();
    // this.getNicheOrder();
    this.getNicheDetail();
    this.getOrderList();
  },
  methods: {
    getNicheDetail() {
      if (!this.id) {
        return;
      }
      this.$axios({
        url: `/admin/mini-worship-buddhist-niche/${this.id}`,
        method: "GET",
        params: { expand: "district,worshipStatus" },
      }).then((res) => {
        dealNiche(res);
        this.infoList = [
          { label: "所在区域", key: "district", value: res.district?.name },
          { label: "是否开放", key: "disabled", value: res.status == 2 ? "否" : "是" },
          { label: "龛位编号", key: "num", value: res.num },
          { label: "龛位状态", key: "name", value: res.statusText },
        ];
      });
    },
    handlePageChange(page) {
      let pager = { ...this.pagination };
      pager.current = page.current;
      this.pagination = pager;
      this.getNicheOrder();
    },
    getOrderList() {
      this.$axios({
        url: "/admin/mini-worship-order",
        method: "GET",
        params: {
          "filter[buddhist_niche_id]": this.id,
          "filter[status]": 1,
          sort: "-id",
        },
        noTempleFilter: true,
      }).then((res) => {
        let list = res.data;
        list.forEach((order) => {
          dealNicheOrder(order);
          order.infoList = [
            { label: "下单时间", full: true, key: "create_time", value: order.create_time },
            { label: "订单编号", full: true, key: "order_num", value: order.order_num },
            { label: "供灯时长", full: true, key: "provideTime", value: order.provideTime },
            { label: "供奉人", full: true, key: "name", value: order.name },
            { label: "手机号码", full: true, key: "mobile", value: order.mobile },
            { label: "供奉物品", full: true, key: "goods", value: order.showGoods },
            { label: "祈福方向", full: true, key: "typeText", value: order.typeText },
            { label: "祈福寄语", full: true, key: "note", value: order.note },
          ];
        });
        this.list = list;
        this.$set(this.pagination, "total", res.page.totalCount);
      });
    },
    getNicheOrder() {
      const num = this.num,
        district = this.district;
      if (num && district) {
        let { current, pageSize } = this.pagination;
        let url = `/admin/mini-worship-status?expand=order&page=${current}&pageSize=${pageSize}&filter[status]=1&filter[district]=${district}&filter[num]=${num}`;
        const form = this.form;
        if (form.range && form.range.length == 2) {
          const start = form.range[0] + " 00:00:00";
          const end = form.range[1] + " 23:59:59";
          url += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
        }
        this.$axios(url, { noTempleFilter: true }).then((res) => {
          const list = [];
          res.data.forEach((item) => {
            const order = item.order;
            if (order) {
              dealNicheOrder(order);
              order.infoList = [
                { label: "下单时间", full: true, key: "create_time", value: order.create_time },
                { label: "订单编号", full: true, key: "order_num", value: order.order_num },
                { label: "供灯时长", full: true, key: "provideTime", value: order.provideTime },
                { label: "供奉人", full: true, key: "name", value: order.name },
                { label: "手机号码", full: true, key: "mobile", value: order.mobile },
                { label: "供奉物品", full: true, key: "goods", value: order.showGoods },
                { label: "祈福方向", full: true, key: "typeText", value: order.typeText },
                { label: "祈福寄语", full: true, key: "note", value: order.note },
              ];
              list.push(order);
            }
          });
          this.list = list;
          this.$set(this.pagination, "total", res.page.totalCount);
        });
      }
    },
    getDisabledRecord() {
      const num = this.num,
        district = this.district;
      if (num && district) {
        const url = `/admin/mini-worship-status?filter[district]=${district}&filter[num]=${num}&filter[status]=2`;
        this.$axios(url).then((res) => {
          if (res.data.length > 0) {
            this.disabled = true;
          }
        });
      }
    },
    back() {
      this.$router.push({
        name: "NicheOverview",
        params: { id: this.id, district: this.district },
      });
    },
  },
};
</script>

<style lang="less">
.niche-info {
  display: flex;
  align-items: center;
  padding: @padding-md;
}
.niche-info-img {
  width: 80px;
  height: 90px;
  background: url(../../assets/light/niche.png) center / contain no-repeat;
}
.niche-info-detail {
  flex: 1;
  margin-left: 48px;
  min-width: 0;
}
.niche-range-query {
  margin: 10px 0;
  .ant-btn {
    margin-top: 4px;
  }
}
.niche-order-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: @padding-md;
  min-height: 0;
}
.niche-order-item {
  padding: 10px;
  border: 1px solid rgba(255, 140, 0, 0.1);
  border-radius: 4px;
  &:not(:first-of-type) {
    margin-top: 8px;
  }
}
.niche-order-list {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
</style>
