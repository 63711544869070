import {getKeyTitle} from "../../js/tool";

export const seasonList = [
    { key: 1, title: '春' },
    { key: 2, title: '夏' },
    { key: 3, title: '秋' },
    { key: 4, title: '冬' },
]

export const solarList = [
    { key: 5, title: "立春" },
    { key: 6, title: "雨水" },
    { key: 7, title: "惊蛰" },
    { key: 8, title: "春分" },
    { key: 9, title: "清明" },
    { key: 10, title: "谷雨" },
    { key: 11, title: "立夏" },
    { key: 12, title: "小满" },
    { key: 13, title: "芒种" },
    { key: 14, title: "夏至" },
    { key: 15, title: "小暑" },
    { key: 16, title: "大暑" },
    { key: 17, title: "立秋" },
    { key: 18, title: "处暑" },
    { key: 19, title: "白露" },
    { key: 20, title: "秋分" },
    { key: 21, title: "寒露" },
    { key: 22, title: "霜降" },
    { key: 23, title: "立冬" },
    { key: 24, title: "小雪" },
    { key: 25, title: "大雪" },
    { key: 26, title: "冬至" },
    { key: 27, title: "小寒" },
    { key: 28, title: "大寒" },
];

export const impressionSourceList = [
    { key: 1, title: "系统发布" },
    { key: 2, title: "用户发布" },
]

export const getImpressionTagList = function (label) {
    const tags = label ? label.split(",").map(Number) : [];
    const list = [...seasonList, ...solarList];
    return tags.map(t => getKeyTitle(list, t));
}

export const getImpressionSourceText = function (source) {
    return getKeyTitle(impressionSourceList, source);
}
