<template>
  <data-list
    ref="dataList"
    class="content"
    url="/admin/mini-bless"
    entity-name="心愿"
    :add-btn="false"
    :query="query"
    hide-show-detail
    hide-edit
    hide-delete
    :modal-width="640"
    :action-width="240"
    :action="action"
    :rules="rules"
    :row-selection="{selectedRowKeys, onChange: onSelectChange}"
    :request-config="{noTempleFilter: true}"
    :columns="columns">
    <template v-slot:button>
      <a-button type="danger" @click="batchDelete">批量删除</a-button>
      <a-dropdown :trigger="['click']">
        <a-button type="primary">一键审核</a-button>
        <a-menu slot="overlay">
          <a-menu-item :key="2" @click="batchAudit(2)">通过</a-menu-item>
          <a-menu-item :key="3" @click="batchAudit(3)">不通过</a-menu-item>
        </a-menu>
      </a-dropdown>
    </template>
    <template v-slot:action="{item}">
      <a-radio-group :disabled="currentItemId == item.id" :options="auditOptions" @change="auditItem($event, item)" style="display: inline-block" v-if="item.status == 1"/>
      <a class="txt-btn danger">删除</a>
    </template>
    <template v-slot:query="{form}">
      <a-form-model-item label="审核状态">
        <a-select
          v-model="form.status"
          :options="statusOptions"
          placeholder="选择审核状态筛选"
          @change="updateList"
          style="width: 180px"></a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import { auditStatusList, getAuditStatusText } from "./culture";

    export default {
        name: "CultureBless",
        data() {
            return {
                columns: [
                    { title: '用户昵称', dataIndex: 'name', customRender: (text, record) => record.miniUser ? record.miniUser.uname : text },
                    { title: '提交时间', dataIndex: 'create_time' },
                    { title: '心愿', dataIndex: 'content' },
                    { title: '审核状态', dataIndex: 'status', customRender: getAuditStatusText },
                ],
                statusOptions: [
                    { key: "", title: "所有心愿" },
                    ...auditStatusList
                ],
                auditOptions: [
                    { label: '通过', value: 2 },
                    { label: '不通过', value: 3 }
                ],
                rules: {
                    name: [{ required: true, message: '请输入发布者', trigger: 'blur' }],
                    labels: [{ required: true, message: '请选择标签', trigger: 'change' }],
                    img: [{ required: true, message: '请上传图片', trigger: 'change' }]
                },
                modalForm: {
                    layout: 'horizontal',
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                },
                query: "&sort=-id&expand=miniUser",
                currentItemId: 0,
                selectedRowKeys: []
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
        },
        methods: {
            batchAudit(status) {
                const keys = this.selectedRowKeys;
                if(keys.length > 0) {
                    const url = "/admin/mini-bless/batch-review";
                    this.$axios({
                        url,
                        method: "POST",
                        data: {
                            ids: keys.join(","),
                            status
                        },
                        noTempleFilter: true
                    }).then(res => {
                        if(res.code == 0) {
                            this.$message.success("一键审核成功");
                            this.selectedRowKeys = [];
                            this.updateList();
                        } else {
                            this.$message.warning(res.msg || "一键审核失败");
                        }
                    })
                } else {
                    this.$message.warning("请至少选择一条数据");
                }
            },
            batchDelete() {
                const keys = this.selectedRowKeys;
                if(keys.length > 0) {
                    this.$confirm({
                        title: '提示',
                        content: `确认删除这${keys.length}条祝福吗？`,
                        onOk: () => {
                            const url = "/admin/mini-bless/batch-delete";
                            this.$axios({
                                url,
                                method: "POST",
                                data: {
                                    ids: keys.join(",")
                                },
                                noTempleFilter: true
                            }).then(res => {
                                if(res.code == 0) {
                                    this.$message.success("批量删除成功");
                                    this.selectedRowKeys = [];
                                    this.updateList();
                                } else {
                                    this.$message.warning(res.msg || "批量删除失败");
                                }
                            })
                        }
                    });
                } else {
                    this.$message.warning("请至少选择一条数据");
                }
            },
            onSelectChange(keys) {
                this.selectedRowKeys = keys;
            },
            auditItem(e, item) {
                this.currentItemId = item.id;
                const value = e.target.value;
                const url = `/admin/mini-bless/${item.id}`;
                this.$axios({
                    url,
                    method: 'PATCH',
                    data: {
                        status: value
                    }
                })
                    .then(() => this.updateList())
                    .finally(() => this.currentItemId = 0);
            },
            updateList() {
                this.$refs.dataList.getList();
            },
        }
    }
</script>

<style scoped>

</style>
