<template>
  <div class="content culture-material">
    <div class="top-picture" v-if="type == 'picture'">
      <div class="data-title">置顶图片</div>
      <div class="top-picture-content">
        <material-item
          type="picture"
          :material="topPicture"
          :selectable="false"
          @preview="previewMaterial(topPicture)"
          @operate="operateMaterial(topPicture, $event)"
          v-if="topPicture"></material-item>
        <form-upload :limit="1" save-file-name @change="handleTopChange" v-else>
          <div class="form-upload-tip">750*1334像素，支持png、jpg格式，小于2M</div>
        </form-upload>
      </div>
      <div class="gap"></div>
    </div>
    <div class="data-title" style="border-bottom: 0">
      <div class="title-left">{{typeName}}列表({{total}}) <span class="tip" v-if="type == 'picture'">注：当未设置”置顶图片“时，每个自然日24:00从图片列表中随机抽取图片进行展示</span></div>
      <a-form-model class="query-form" layout="inline">

      </a-form-model>
      <div class="title-btn">
        <a-button type="primary" @click="showAdd">{{addBtn}}</a-button>
      </div>
    </div>
    <div class="flex-grow scroll-area">
      <div class="material-area">
        <div class="material-list" v-if="list && list.length > 0">
          <material-item
            :type="type"
            :material="item"
            :selected="selectIds.indexOf(item.id) >= 0"
            @preview="previewMaterial(item)"
            @change="handleSelect(item, $event)"
            @operate="operateMaterial(item, $event)"
            v-for="item in list"
            :key="item.id"></material-item>
        </div>
        <a-empty :image="simpleImage" :description="`暂无${typeName}`" v-else-if="list"/>
      </div>
    </div>
    <div class="footer">
      <a-space>
        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>
        <a-button @click="batchDelete">批量删除</a-button>
      </a-space>
      <a-pagination show-quick-jumper v-model="page" :page-size="pageSize" :total="total"/>
    </div>
    <a-modal
      :title="form.id ? '重命名' : type == 'video' ? '上传视频' : '上传图片'"
      :maskClosable="false"
      :closable="false"
      :width="800"
      @ok="handleConfirm"
      v-model="visible">
      <a-form-model
        ref="materialForm"
        label-align="left"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="重命名" prop="name" :wrapper-col="wrapperCol" v-if="form.id">
          <a-input v-model="form.name" placeholder="请输入文件名称"></a-input>
        </a-form-model-item>
        <template v-else>
          <a-form-model-item label="上传图片" prop="image" :wrapper-col="wrapperCol" v-if="type == 'picture'">
            <form-upload v-model="form.image" save-file-name upload-tip="750*1334像素，支持png、jpg格式，小于2M"/>
          </a-form-model-item>
          <template v-else>
            <a-form-model-item label="视频类型" prop="type" :wrapper-col="wrapperCol">
              <a-select v-model="form.type" :options="typeOptions" placeholder="请选择视频类型"/>
            </a-form-model-item>
            <a-form-model-item label="视频上传" prop="video" :wrapper-col="wrapperCol">
              <form-upload
                v-model="form.video"
                save-file-name
                media-type="video"
                upload-tip="支持MP4格式，支持批量上传，每个视频小于500M"/>
            </a-form-model-item>
            <a-form-model-item label="视频封面" prop="img" :wrapper-col="wrapperCol">
              <form-upload v-model="form.img" upload-tip="750*1334像素，支持png、jpg格式，小于2M"/>
            </a-form-model-item>
          </template>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
    import {Empty} from "ant-design-vue";
    import materialItem from "./material-item";

    const typeConfig = {
        picture: {
            name: "图片"
        },
        video: {
            name: "视频"
        }
    }
    export default {
        name: "CultureMaterial",
        components: {
            materialItem,
        },
        data() {
            return {
                query: "",
                rules: {
                    name: [{required: true, message: "请输入文件名称", trigger: "blur"}],
                    image: [{required: true, message: "请上传图片", trigger: "change"}],
                    img: [{required: true, message: "请上传视频封面", trigger: "change"}],
                    video: [{required: true, message: "请上传视频", trigger: "change"}],
                    type: [{required: true, message: "请选择视频类型", trigger: "change"}],
                },
                form: {},
                list: null,
                typeOptions: [{key: 1, title: "梵呗音乐"}],
                page: 1,
                pageSize: 10,
                total: 0,
                checkAll: false,
                indeterminate: false,
                topPicture: null,
                material: null,
                visible: false,
                wrapperCol: {span: 20},
                selectIds: [],
                simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            }
        },
        computed: {
            type() {
                return this.$route.name == 'CulturePicture' ? 'picture' : 'video';
            },
            url() {
                return `/admin/mini-${this.type}`
            },
            typeName() {
                const conf = typeConfig[this.type];
                return conf ? conf.name : "";
            },
            addBtn() {
                return `上传${this.typeName}`
            }
        },
        watch: {
            url() {
                this.page = 1;
                this.total = 0;
                this.getData();
            }
        },
        created() {
            this.getData();
        },
        methods: {
            hideViewer() {
                this.current = null;
                this.show = false;
                this.urls = [];
            },
            batchDelete() {
                const ids = this.selectIds;
                if(ids.length == 0) {
                    this.$message.warning(`请选择${this.typeName}`);
                    return;
                }
                this.$confirm({
                    title: '提示',
                    content: `确认删除这${ids.length}个${this.typeName}吗？`,
                    onOk: () => {
                        const url = `${this.url}/batch-delete`;
                        this.$axios({
                            url,
                            method: "POST",
                            data: {
                                ids: ids.join(",")
                            }
                        }).then(res => {
                            if(res.code == 0) {
                                this.selectIds = [];
                                this.getList();
                                this.$message.success("批量删除成功");
                            } else {
                                this.$message.warning(res.msg || "批量删除失败");
                            }
                        })
                    }
                })
            },
            showAdd() {
                const form = this.type == 'picture' ? {} : { type: 1 };
                this.form = form;
                this.visible = true;
            },
            handleConfirm() {
                this.$refs.materialForm.validate((valid) => {
                    if (valid) {
                        const form = this.form;
                        let url = this.url, method = "POST", data;
                        if(form.id) {
                            url += `/${form.id}`;
                            method = 'PATCH';
                            if (this.type == 'picture') {
                                data = { name: form.name };
                            } else {
                                data = { title: form.name };
                            }
                        } else {
                            if(this.type == 'picture') {
                                const img = form.image[0];
                                data = {
                                    name: img.name,
                                    img: img.url
                                }
                            } else {
                                const file = form.video[0];
                                data = {
                                    title: file.name,
                                    url: file.url,
                                    img: form.img,
                                    type: form.type
                                }
                            }
                        }
                        this.$axios({
                            url,
                            method,
                            data
                        }).then(res => {
                            if(form.id) {
                                if (this.type == 'picture') {
                                    this.$set(this.material, "name", res.name);
                                } else {
                                    this.$set(this.material, "title", res.title);
                                }
                            } else {
                                this.getList();
                            }
                            this.visible = false;
                        });
                    }
                });
            },
            handleSelect(material, checked) {
                const id = material.id;
                const ids = this.selectIds
                const i = ids.indexOf(id);
                if(checked) {
                    if(i === -1) {
                        ids.push(id)
                    }
                } else {
                    if(i >= 0) {
                        ids.splice(i, 1);
                    }
                }
                this.setCheckAllBox();
            },
            setCheckAllBox() {
                const ids = this.selectIds;
                const l = ids.length;
                if(l == 0) {
                    this.checkAll = false;
                    this.indeterminate = false;
                } else if(l == this.list.length) {
                    this.checkAll = true;
                    this.indeterminate = false;
                } else {
                    this.indeterminate = true;
                }
            },
            handleTopChange(imgList) {
                const img = imgList[0];
                if (img) {
                    this.$axios({
                        url: "/admin/mini-picture",
                        method: "POST",
                        data: {
                            is_top: 1,
                            img: img.url,
                            name: img.name
                        }
                    }).then(res => {
                        this.topPicture = res;
                    })
                }
            },
            previewMaterial(material) {
                if(this.type == 'video') {
                    this.$store.commit("setViewVideo", {
                        url: material.url,
                        poster: material.img,
                        title: material.title
                    });
                } else {
                    this.$store.dispatch("showImgList", [{
                        url: material.img,
                        title: material.name
                    }]);
                }
            },
            operateMaterial(material, type) {
                this.material = material;
                if (type == 'rename') {
                    this.form = {
                        id: material.id,
                        name: material.title || material.name
                    }
                    this.visible = true;
                } else {
                    this.$confirm({
                        title: '提示',
                        content: `确认删除此${material.is_top == 1 ? '置顶' : ''}${this.typeName}吗？`,
                        onOk: () => {
                            this.deleteMaterial(material).then(() => {
                                this.getData();
                            });
                        }
                    })
                }
            },
            deleteMaterial(material) {
                return this.$axios({
                    url: `${this.url}/${material.id}`,
                    method: "DELETE",
                })
            },
            onCheckAllChange(e) {
                const checked = e.target.checked;
                this.indeterminate = false;
                if(checked) {
                    this.selectIds = this.list.map(item => item.id);
                } else {
                    this.selectIds = [];
                }
                this.checkAll = checked;
            },
            getData() {
                if (this.type == "picture") {
                    this.getTopPicture();
                }
                this.getList();
            },
            getTopPicture() {
                const url = "/admin/mini-picture?pageSize=1&filter[is_top]=1";
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    if (res.data.length > 0) {
                        this.topPicture = res.data[0];
                    } else {
                        this.topPicture = null;
                    }
                });
            },
            getList() {
                const page = this.page, pageSize = this.pageSize;
                const url = `${this.url}?page=${page}&pageSize=${pageSize}&sort=-id&filter[is_top]=2`;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    this.list = res.data;
                    this.total = res.page.totalCount;
                    this.checkAll = false;
                    this.indeterminate = false;
                    this.selectIds = [];
                });
            }
        }
    }
</script>

<style lang="less">
  .culture-material {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .top-picture-content {
    margin-top: 16px;
  }
  .material-area {
    overflow: hidden;
  }
  .material-list {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    .material-item {
      margin: 12px;
    }
  }
</style>
