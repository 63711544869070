<template>
  <data-list
    ref="dataList"
    class="content"
    url="/admin/mini-impression"
    entity-name="图片"
    :add-btn="audit ? false : '图片发布'"
    :query="query"
    hide-show-detail
    :hide-edit="audit"
    :hide-delete="audit"
    :show-audit="audit"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :custom-url="customUrl"
    :modal-width="640"
    :action-width="200"
    :action="action"
    :rules="rules"
    :row-selection="{selectedRowKeys, onChange: onSelectChange}"
    :request-config="{noTempleFilter: true}"
    :columns="columns">
    <template v-slot:button>
      <a-dropdown :trigger="['click']" v-if="audit">
        <a-button type="primary">一键审核</a-button>
        <a-menu slot="overlay">
          <a-menu-item :key="2" @click="batchAudit(2)">通过</a-menu-item>
          <a-menu-item :key="3" @click="batchAudit(3)">不通过</a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-button type="danger" @click="batchDelete" v-else>批量删除</a-button>
    </template>
    <template v-slot:action="{item}" v-if="audit">
      <a-radio-group :disabled="currentItemId == item.id" :options="auditOptions" @change="auditItem($event, item)" />
    </template>
    <template v-slot:query="{form}" v-if="!audit">
      <a-form-model-item label="标签">
        <a-select
          v-model="form.label"
          :options="queryLabelOptions"
          placeholder="选择标签筛选"
          @change="updateList"
          style="width: 180px"></a-select>
      </a-form-model-item>
      <a-form-model-item label="来源">
        <a-select
          v-model="form.source"
          :options="querySourceOptions"
          placeholder="选择来源筛选"
          @change="updateList"
          style="width: 180px"></a-select>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="发布者" prop="name">
        <a-input v-model="form.name" placeholder="请输入发布者"></a-input>
      </a-form-model-item>
      <a-form-model-item label="标签" prop="labels">
        <a-select v-model="form.labels" mode="multiple" :options="labelOptions" placeholder="请选择标签" @change="handleChange"></a-select>
      </a-form-model-item>
      <a-form-model-item label="上传图片" prop="img">
        <form-upload v-model="form.img" :limit="-1"></form-upload>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import {
        getImpressionSourceText,
        getImpressionTagList, impressionSourceList,
        seasonList,
        solarList
    } from "../../common/constant/culture/impression";
    import {clone} from "../../common/js/tool";

    export default {
        name: "CultureImpression",
        data() {
            return {
                columns: [
                    { title: '发布者', dataIndex: 'name', customRender: (text, record) => record.miniUser ? record.miniUser.uname : text },
                    { title: '发布时间', dataIndex: 'create_time' },
                    { title: '标签', dataIndex: 'label', customRender: text => getImpressionTagList(text).map(t => <a-tag color="#FFCE7E"># {t}</a-tag>) },
                    {
                        title: '图片',
                        dataIndex: 'img',
                        width: 360,
                        customRender: text => {
                            const images = text.split(",");
                            const showImages = images.slice(0, 3);
                            return <a-space>
                                { showImages.map(img => <s-img src={img} size="small" />) }
                                { images.length > 3 ? <span>共{images.length}张图片</span> : null }
                            </a-space>
                        }
                    },
                    { title: '来源', dataIndex: 'source', customRender: getImpressionSourceText },
                ],
                queryLabelOptions: [
                    { key: 0, title: "全部标签" },
                    ...seasonList,
                    ...solarList
                ],
                querySourceOptions: [
                    { key: 0, title: "所有来源" },
                    ...impressionSourceList,
                ],
                labelOptions: [...clone(seasonList), ...clone(solarList)],
                auditOptions: [
                    { label: '通过', value: 2 },
                    { label: '不通过', value: 3 }
                ],
                rules: {
                    name: [{ required: true, message: '请输入发布者', trigger: 'blur' }],
                    labels: [{ required: true, message: '请选择标签', trigger: 'change' }],
                    img: [{ required: true, message: '请上传图片', trigger: 'change' }]
                },
                modalForm: {
                    layout: 'horizontal',
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                },
                currentItemId: 0,
                selectedRowKeys: []
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
            audit() {
                return this.$route.name == 'ImpressionAudit';
            },
            query() {
                let res = "&sort=-id&expand=miniUser";
                if(this.audit) {
                    res += "&filter[status]=1"
                } else {
                    res += "&filter[status]=2"
                }
                return res;
            }
        },
        methods: {
            batchAudit(status) {
                const keys = this.selectedRowKeys;
                if(keys.length > 0) {
                    const url = "/admin/mini-impression/batch-review";
                    this.$axios({
                        url,
                        method: "POST",
                        data: {
                            ids: keys.join(","),
                            status
                        },
                        noTempleFilter: true
                    }).then(res => {
                        if(res.code == 0) {
                            this.$message.success("一键审核成功");
                            this.selectedRowKeys = [];
                            this.updateList();
                        } else {
                            this.$message.warning(res.msg || "一键审核失败");
                        }
                    })
                } else {
                    this.$message.warning("请至少选择一条数据");
                }
            },
            batchDelete() {
                const keys = this.selectedRowKeys;
                if(keys.length > 0) {
                    this.$confirm({
                        title: '提示',
                        content: `确认删除这${keys.length}条数据吗？`,
                        onOk: () => {
                            const url = "/admin/mini-impression/batch-delete";
                            this.$axios({
                                url,
                                method: "POST",
                                data: {
                                    ids: keys.join(",")
                                },
                                noTempleFilter: true
                            }).then(res => {
                                if(res.code == 0) {
                                    this.$message.success("批量删除成功");
                                    this.selectedRowKeys = [];
                                    this.updateList();
                                } else {
                                    this.$message.warning(res.msg || "批量删除失败");
                                }
                            })
                        }
                    });
                } else {
                    this.$message.warning("请至少选择一条数据");
                }
            },
            onSelectChange(keys) {
                this.selectedRowKeys = keys;
            },
            auditItem(e, item) {
                this.currentItemId = item.id;
                const value = e.target.value;
                const url = `/admin/mini-impression/${item.id}`;
                this.$axios({
                    url,
                    method: 'PATCH',
                    data: {
                        status: value
                    }
                })
                    .then(() => this.updateList())
                    .finally(() => this.currentItemId = 0);
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            handleChange(val) {
                const labelOptions = [...this.labelOptions];
                if(val.length >= 2) {
                    labelOptions.forEach(item => val.indexOf(item.key) === - 1 && (item.disabled = true))
                } else {
                    labelOptions.forEach(item => item.disabled = false)
                }
            },
            customUrl(form) {
                let res = ""
                if(!this.audit) {
                    if(form.label) {
                        res += `&filter[label][fis]=${form.label}`;
                    }
                    if(form.source) {
                        res += `&filter[source]=${form.source}`;
                    }
                }
                return res;
            },
            beforeUpdate: function (form, fn) {
                form.label = form.labels.join(",");
                if(!form.id) {
                    form.status = 2;
                }
                fn();
            },
            beforeEdit: function(detail, fn) {
                if(detail.label) {
                    detail.labels = detail.label.split(",").map(Number);
                }
                fn();
            },
        }
    }
</script>

<style scoped>

</style>
