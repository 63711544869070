import { render, staticRenderFns } from "./LightAdmin.vue?vue&type=template&id=ab19b804&scoped=true&"
import script from "./LightAdmin.vue?vue&type=script&lang=js&"
export * from "./LightAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab19b804",
  null
  
)

export default component.exports