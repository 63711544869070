<template>
  <div class="content md no-padding niche-create-order" style="position: relative">
    <div class="order-create-area">
      <niche-order-form :step="1" ref="orderForm" v-show="step == 1"></niche-order-form>
      <div class="order-create-preview" v-show="step == 2">
        <div class="other-title">支付信息</div>
        <div class="order-pay-info" v-if="order.detail">
          <div class="pay-info-piece">
            <div class="order-pay-title">
              <div class="pay-title-text">龛位</div>
              <div class="pay-title-value">{{ getShowPrice(order.detail.nichePrice) }}</div>
            </div>
            <div class="pay-info-item">
              <div class="pay-info-label">供奉位置</div>
              <div class="pay-info-value">{{ order.detail.showArea }}</div>
            </div>
            <div class="pay-info-item">
              <div class="pay-info-label">供奉时长</div>
              <div class="pay-info-value">{{ order.detail.showTime }}</div>
            </div>
          </div>
          <div class="pay-info-piece" v-if="order.detail.goods && order.detail.goods.length > 0">
            <div class="order-pay-title">
              <div class="pay-title-text">供奉物品</div>
              <div class="pay-title-value">
                {{ getShowPrice(order.detail.price - order.detail.nichePrice) }}
              </div>
            </div>
            <div class="pay-info-item text-right" v-for="g in order.detail.goods" :key="g.id">
              <div class="pay-info-label">{{ g.name }} × {{ g.count }}</div>
              <div class="pay-info-value">{{ getShowPrice(g.count * g.price) }}</div>
            </div>
          </div>
          <div class="pay-info-piece">
            <div class="order-pay-title">
              <div class="pay-title-text">供奉信息</div>
            </div>
            <div class="pay-info-item" v-for="(d, i) in order.detail.itemList" :key="i">
              <div class="pay-info-label">{{ d.label }}</div>
              <div class="pay-info-value">{{ d.value }}</div>
            </div>
          </div>
          <div class="pay-info-piece">
            <div class="order-pay-title">
              <div class="pay-title-text">总金额</div>
              <div class="pay-title-value">{{ getShowPrice(order.detail.price) }}</div>
            </div>
          </div>
        </div>
        <niche-order-form :step="2" ref="previewForm"></niche-order-form>
      </div>
    </div>
    <a-space class="create-order-button">
      <a-button @click="cancel">取消</a-button>
      <a-button type="primary" @click="prev" v-show="step > 1">上一步</a-button>
      <a-button type="primary" @click="next">{{
        step == 2 ? "确定" : "下一步"
      }}</a-button>
    </a-space>
    <div class="order-niche-select" v-if="select">
      <span>已选</span>
      <div class="niche-select-info">
        <span>{{ select.district.name }} {{ select.num }}</span>
        <a-icon type="close" class="niche-select-remove" @click="removeSelect" />
      </div>
    </div>
  </div>
</template>

<script>
import nicheOrderForm from "./niche-order-form";
import { getIntegerAndDecimal, getLightAreaText, getShowPrice } from "./light";
import { omit } from "../../common/js/tool";
import { getUserWxId } from "../../common/js/storage";

export default {
  name: "NicheOrderCreate",
  components: {
    nicheOrderForm,
  },
  data() {
    return {
      step: 1,
      select: null,
      order: {},
    };
  },
  created() {
    this.getSelect();
  },
  methods: {
    getShowPrice,
    getLightAreaText,
    getSelect() {
      const id = this.$route.query.select;
      if(id) {
        const niche = this.$store.getters.niche;
        if(niche && niche.id == id) {
          this.select = niche;
        } else {
          this.$axios(`/admin/mini-worship-buddhist-niche/${id}?expand=district`).then(res => {
            this.select = res;
          })
        }
      } else {
        this.$router.replace({name: "NicheOverview"})
      }
    },
    prev() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    next() {
      const step = this.step;
      if (step == 1) {
        this.$refs.orderForm.submit().then((form) => {
          this.previewOrder({
            ...form,
            note: form.note ? form.note[1] : null,
            type: form.note ? form.note[0] : null
          })
          this.step += 1;
        });
      } else if (step == 2) {
        this.$refs.previewForm.submit().then((form) => {
          this.insertOrder(form);
        });
      }
    },
    insertOrder(form) {
      const { data, detail } = this.order;
      detail.bill = form.bill;
      detail.processor = form.processor;
      data.order_info = JSON.stringify(detail);
      this.$axios({
        url: "/admin/mini-worship-order/add",
        method: "POST",
        data,
      }).then((res) => {
        if (res.error == 0) {
          this.$message.success("订单已创建");
          this.$router.push({
            name: "NicheOrderDetail",
            params: { id: res.order.id },
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getShowTime(year_count) {
      const currentDate = new Date(); // 获取当前时间
      const twoYearsLater = new Date(currentDate); // 创建一个新的Date对象，将其设为当前时间
      twoYearsLater.setFullYear(currentDate.getFullYear() + year_count); // 设置年份为当前年份加2
      return `${currentDate.pattern("yyyy年M月d日")}-${twoYearsLater.pattern("yyyy年M月d日")}`;
    },
    previewOrder(form) {
      const data = omit(form, ["goods", "bill", "processor"]);
      const { id, num, district_id, district, price: priceNum } = this.select;
      const nichePrice = priceNum * data.year_count;
      let price = nichePrice;
      if (form.goods && form.goods.length > 0) {
        const itemInfo = [];
        form.goods.forEach((g) => {
          itemInfo.push({ id: g.id, num: g.count });
          price += g.price * g.count;
        });
        data.item_info = JSON.stringify(itemInfo);
      }
      data.buddhist_niche_id = id;
      data.district = district_id;
      data.price = price;
      data.cat = 3;
      data.admin_id = getUserWxId();
      data.status = 1;
      data.min_user_id = -2;

      // 保持和小程序端一致
      const detail = {
        ...form,
        num,
        buddhist_niche_id: id,
        nichePrice,
        district: district_id,
        year_count: form.year_count,
        showTime: this.getShowTime(form.year_count),
        price,
        bill: form.bill,
        processor: form.processor,
        showArea: `${district.name} ${num}`,
        ...getIntegerAndDecimal(price),
        itemList: [
          { label: "供奉人", value: form.name },
          { label: "祈福寄语", value: form.note },
          { label: "手机号码", value: form.mobile },
        ],
      };
      this.order = {
        data,
        detail,
      };
    },
    cancel() {
      this.$router.go(-1);
    },
    removeSelect() {
      this.select = null;
    },
  },
};
</script>

<style lang="less">
.niche-create-order {
  display: flex;
  flex-direction: column;
  position: relative;
}
.order-create-area {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
.create-order-button {
  padding: 14px 0;
  border-top: var(--border);
  width: 100%;
  background-color: @component-background;
  justify-content: center;
}
.order-niche-select {
  display: flex;
  align-items: center;
  position: absolute;
  left: 16px;
  bottom: 0;
  height: 60px;
}
.order-create-preview {
  padding: @padding-md;
}
.pay-info-piece {
  padding: 10px 20px;
  width: 480px;
}
.order-pay-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  font-size: 16px;
}
.pay-title-text {
  font-weight: 600;
}
.pay-info-item {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  padding-left: 20px;
  &.text-right {
    .pay-info-value {
      text-align: right;
    }
  }
}
.pay-info-label {
  width: 5em;
}
.pay-info-value {
  flex: 1;
  min-width: 0;
}
.niche-select-info {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 6px 12px;
  height: 32px;
  border-radius: 4px;
  background-color: #ff8c00;
  color: @white;
  line-height: 1;
  font-size: 16px;
}
.niche-select-remove {
  margin-left: 8px;
  color: @white;
  cursor: pointer;
}
</style>
