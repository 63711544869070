<template>
  <div class="overview-content">
    <div class="view-tip">点击相应区域，选择供奉区域!</div>
    <div class="view-box" v-if="areaList.length > 0">
      <div class="view-top area-btn horizontal" @click="selectDistrict(2)">{{ getDistrictName(2) }} <span class="area-niche-num">{{getDistrictNicheCount(2)}}</span></div>
      <div class="flex-box align-center justify-between">
        <div class="view-left area-btn vertical" @click="selectDistrict(3)">{{ getDistrictName(3) }} <span class="area-niche-num">{{getDistrictNicheCount(3)}}</span></div>
        <img src="../../../assets/light/layout.png" class="view-center layout-img" />
        <div class="view-right area-btn vertical" @click="selectDistrict(1)">{{ getDistrictName(1) }} <span class="area-niche-num">{{getDistrictNicheCount(1)}}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverviewContent",
  props: {
    areaList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    selectDistrict(id) {
      this.$emit("select", id);
    },
    getDistrictName(id) {
      let target = this.areaList.find((item) => item.id == id);
      return target?.name;
    },
    getDistrictNicheCount(id){
      let target = this.areaList.find((item) => item.id == id);
      return target?.nicheCount || 0;
    }
  },
};
</script>

<style lang="less" scoped>
.overview-content {
  padding: 12px;
  overflow: auto;
}
.view-tip {
  font-size: 20px;
}
.view-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #5f161a;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 10px;
  cursor: pointer;
  &.horizontal {
    width: 224px;
    height: 55px;
    background: url("../../../assets/light/h_district.png") no-repeat;
    background-size: 100% 100%;
  }
  &.vertical {
    writing-mode: vertical-lr;
    width: 55px;
    height: 224px;
    background: url("../../../assets/light/v_district.png") no-repeat;
    background-size: 100% 100%;
  }
}
.area-niche-num {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  writing-mode: horizontal-tb;
  transform: translateY(100%);
  letter-spacing: 0;
}
.layout-img {
  width: 604px;
  height: 326px;
  object-fit: contain;
  margin: 64px 80px 0;
}
</style>
