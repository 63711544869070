import {getKeyTitle} from "../../common/js/tool";

export const auditStatusList = [
    { key: 1, title: "待审核" },
    { key: 2, title: "审核通过" },
    { key: 3, title: "不通过" },
]

export const getAuditStatusText = function (status) {
    return getKeyTitle(auditStatusList, status);
}
