<template>
  <div class="content md flex-box vertical">
    <div class="flex-grow scroll-area">
      <form-area
        ref="lightIntroForm"
        class="light-intro-form"
        layout="horizontal"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
        :items="items"
        :entity="form"
        @cancel="cancel()"
        @confirm="setIntro"
        v-if="edit"></form-area>
      <div class="detail-info" v-else>
        <div class="detail-info-item">
          <div class="detail-info-label">供灯介绍</div>
          <div class="detail-info-value">
            <div class="rich-preview" v-html="intro" v-if="intro"></div>
            <span v-else>无</span>
          </div>
        </div>
      </div>
    </div>
    <a-space class="detail-footer" v-show="!edit">
      <a-button type="primary" @click="editIntro">编辑</a-button>
    </a-space>
  </div>
</template>

<script>
    export default {
        name: "LightIntro",
        data() {
            return {
                form: {},
                items: [
                    {
                        key: 'intro',
                        label: '供灯介绍',
                        component: 'rich-editor',
                        props: {
                            placeholder: '请输入供灯介绍'
                        },
                    }
                ],
                intro: "",
                edit: false
            }
        },
        created() {
            this.getIntro();
        },
        methods: {
            getIntro() {
                this.$axios("/admin/mini-worship-order/get-info").then(res => {
                    let intro = res;
                    try {
                        intro = JSON.parse(res).intro;
                    } catch (e) {
                        console.error(e);
                    }
                    this.intro = intro;
                })
            },
            setIntro(form) {
                const intro = form.intro;
                this.$axios({
                    url: "/admin/mini-worship-order/set-info",
                    method: "POST",
                    data: JSON.stringify({intro}),
                    noTempleFilter: true
                }).then(() => {
                    this.intro = intro;
                    this.edit = false;
                })
            },
            cancel() {
                this.edit = false;
            },
            editIntro() {
                this.form = {
                    intro: this.intro
                }
                this.edit = true;
            }
        }
    }
</script>

<style lang="less">
.light-intro-form {
  height: 100%;
}
</style>
