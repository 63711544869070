<template>
  <div class="material-item" v-if="material">
    <div class="material-image">
      <div class="material-image-box" @click="previewFile">
        <img :src="material.img" />
      </div>
      <div class="material-play-box" @click="previewFile" v-if="type == 'video'">
        <a-icon type="play-circle" class="material-play-icon" />
      </div>
      <a-dropdown :trigger="['click']" placement="bottomRight" class="material-operate">
        <a-button size="small" shape="circle" icon="ellipsis" />
        <a-menu slot="overlay">
          <a-menu-item key="rename" @click="operate('rename')">重命名</a-menu-item>
          <a-menu-item key="delete" @click="operate('delete')">删除</a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <div class="material-name">
      <a-checkbox slot="title" v-model="checked" v-if="selectable">{{ material.name || material.title }}</a-checkbox>
      <span v-else>{{ material.name || material.title }}</span>
    </div>
  </div>
</template>

<script>
    export default {
        name: "material-item",
        props: {
            material: Object,
            type: String,
            selectable: {
                type: Boolean,
                default: true
            },
            selected: Boolean,
        },
        computed: {
            checked: {
                get() {
                    return !!this.selected
                },
                set(val) {
                    this.$emit("change", val)
                }
            }
        },
        methods: {
            operate(type) {
                this.$emit("operate", type);
            },
            previewFile() {
                this.$emit("preview");
            }
        }
    }
</script>

<style lang="less">
.material-item {
  width: 300px;
}
.material-name {
  padding: 6px 12px;
  background-color: @background-color-base;
  border: 1px solid @border-color-base;
  font-size: 16px;
}
.material-image {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.material-image-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.material-operate {
  position: absolute;
  top: 6px;
  right: 6px;
}
.material-play-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  .material-play-icon {
    color: @component-background;
    font-size: 48px;
    line-height: 1;
  }
}
</style>
