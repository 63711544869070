<template>
    <div class="content md">
        <system-admin type="oa"></system-admin>
    </div>
</template>

<script>
    import SystemAdmin from "../../layouts/SystemAdmin";
    export default {
        name: "OaAdmin",
        components: {
            SystemAdmin
        }
    }
</script>

<style scoped>

</style>
