<template>
  <div class="content md buddha-reply">
    <div class="left-area">
      <div class="s-bar">
        <div class="flex-box align-center" v-show="refreshing">
          <a-icon type="loading" />
          <span class="msg-loading-text">收取中...</span>
        </div>
        <div class="s-bar-title" v-show="!refreshing">问佛</div>
        <a-icon type="sync" class="refresh-btn" :class="{refreshing}" @click="refresh()"/>
      </div>
      <div class="area-ctx">
        <ul class="sch-list">
          <li class="sch-item" :class="{active: chat && chat.id == c.id}" v-for="c in list" :key="c.id" @click="selectUser(c)">
            <a-avatar :src="c.miniUser ? c.miniUser.avatar : undefined" :size="40" />
            <div class="sch-info">
              <div class="sch-name">{{c.miniUser ? c.miniUser.uname : '微信用户'}}</div>
              <div class="sch-msg">{{c.lastChat ? c.lastChat.content : c.content}}</div>
            </div>
            <div class="msg-time">{{getTimeText(c.update_time)}}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="right-area" :style="{visibility: chat ? 'visible' : 'hidden'}">
      <div class="area-ti">{{chat ? chat.miniUser.uname : ''}}</div>
      <div class="area-ctx" id="msg_area">
        <div class="l-tip" @click.stop="getChatMsgList" v-if="msgMore">点击加载更多</div>
        <div class="l-tip no-more" v-else>没有更多了</div>
        <div class="msg-item" :class="{'msg-right': !item.mini_user_id}" v-for="(item, i) in msgList" :key="item.key || item.id">
          <a-avatar :src="!item.mini_user_id ? avatar : (chat && chat.miniUser ? chat.miniUser.avatar : undefined)" :size="40" />
          <div class="msg-detail">
            <div class="msg-content">
              <div class="msg-text">
                <p class="msg-text-p" v-for="(p, j) in item.content ? item.content.split(/\n|\s/) : []" :key="j">{{p}}</p>
              </div>
            </div>
            <a-icon class="el-icon-warning"  type="exclamation-circle" v-if="item.error" @click="reSend(item, i)"/>
            <div class="loading" v-else-if="item.loading"></div>
          </div>
        </div>
      </div>
      <div class="area-input">
        <textarea v-model="content" v-on:keyup.enter="send"></textarea>
        <div class="send" @click="send">发送</div>
      </div>
    </div>
  </div>
</template>

<script>
    import {getDate} from "../../common/js/tool";
    import {getUserWxId} from "../../common/js/storage";
    import Scroller from "../../common/js/scroll";

    export default {
        name: "BuddhaReply",
        data() {
            return {
                chat: null,
                list: [],
                msgList: [],
                content: "",
                refreshing: false,
                page: 1,
                pageSize: 20,
                loading: false,
                more: true,
                msgPage: 1,
                msgPageSize: 20,
                msgLoading: false,
                msgMore: true,
                sending: false,
                avatar: require("../../assets/culture/avatar.png"),
                chatTimer: null,
                msgTimer: null,
                scroller: null
            }
        },
        watch: {
            chat(val) {
                this.clearTimer("msgTimer");
                if(val) {
                    this.msgList = [];
                    this.msgPage = 1;
                    this.msgLoading = false;
                    this.msgMore = true;
                    this.getChatMsgList();
                }
            }
        },
        created() {
            this.getMsgList();
        },
        mounted() {
            this.scroller = new Scroller(this.$el.querySelector("#msg_area"),300);
        },
        beforeDestroy() {
            this.clearTimer("chatTimer");
            this.clearTimer("msgTimer");
        },
        methods: {
            refresh(hideLoading) {
                const page = this.page, pageSize = this.pageSize;
                if(this.refreshing) return;
                !hideLoading && (this.refreshing = true);
                const more = this.more;
                const size = (more ? (page - 1) : page) * pageSize;
                const url = `/admin/mini-ask-buddha?expand=miniUser,lastChat&pageSize=${size}`;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    this.list = res.data;
                }).finally(() => this.refreshing = false);
            },
            selectUser(c) {
                if(this.msgLoading) return;
                this.chat = c;
            },
            clearTimer(type) {
                let timer = this[type];
                timer && clearTimeout(timer);
            },
            pollChat() {
                this.chatTimer = setTimeout(() => {
                    this.refresh(true).finally(this.pollChat);
                }, 5000);
            },
            pollMsg() {
                this.msgTimer = setTimeout(() => {
                    this.getNewMsg().finally(this.pollMsg);
                }, 5000);
            },
            getNewMsg() {
                const chat = this.chat;
                // 正在发送中不轮询
                if(!chat || this.sending) return Promise.resolve();
                const msgList = this.msgList;
                let last = null;
                if(msgList.length > 0) {
                    // 最后一条回复不是初始对话
                    if(!msgList[msgList.length - 1].key) {
                        last = msgList[msgList.length - 1]
                    }
                }
                let url = `/admin/mini-ask-detail?pageSize=1000&filter[ask_id]=${chat.id}`;
                // 查询当前消息之后的消息
                if(last) {
                    url += `&filter[id][gt]=${last.id}`;
                }
                return this.$axios(url, {noTempleFilter: true}).then(res => {
                    const msgList = this.msgList;
                    let last = null;
                    res.data.forEach(item => {
                        // 去重 防止发送后再去轮询 得到重复的消息
                        if(msgList.findIndex(msg => msg.id == item.id) === -1) {
                            msgList.push(item);
                            last = item;
                        }
                    })
                    if(last) {
                        this.updateChatList(last);
                    }
                });
            },
            send() {
                if(!this.content) return;
                let item = {
                    ask_id: this.chat.id,
                    content: this.content,
                    loading: true,
                    self: true,
                };
                const msgList = this.msgList;
                let i = msgList.length;
                msgList.push(item);
                this.$nextTick(() => {
                    this.scroller.scrollBottom();
                });
                this.reSend(item, i);
            },
            reSend(item, i) {
                if(this.sending) return;
                this.sending = true;
                this.$axios({
                    url: "/admin/mini-ask-detail",
                    method: "POST",
                    loadingText: false,
                    data: {
                        ask_id: item.ask_id,
                        admin_user_id: getUserWxId(),
                        content: item.content
                    }
                }).then(res => {
                    this.content = "";
                    this.$set(this.msgList, i, res);
                    this.updateChatList(res);
                }).catch(() => {
                    this.content = "";
                    this.$set(item, "error", true);
                }).finally(() => this.sending = false);
            },
            updateChatList(msg) {
                const chat = this.chat;
                const list = this.list;
                const index = list.findIndex(item => item.id == chat.id);
                chat.lastChat = msg;
                list.splice(index, 1);
                list.unshift(chat);
            },
            getChatMsgList() {
                let loading = this.msgLoading, more = this.msgMore;
                const chat = this.chat;
                if(!chat || loading || !more) return;
                this.msgLoading = true;
                const page = this.msgPage, pageSize = this.msgPageSize;
                const url = `/admin/mini-ask-detail?sort=-id&page=${page}&pageSize=${pageSize}&filter[ask_id]=${chat.id}`;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    let list = res.data;
                    list.reverse();
                    list = list.concat(this.msgList);
                    if (page >= res.page.pageCount) {
                        list.unshift({...chat, key: `chat_${chat.id}`});
                        this.msgMore = false;
                    } else {
                        this.msgPage += 1;
                    }
                    this.msgList = list;
                    this.$nextTick(() => {
                        this.scroller.scrollBottom();
                    });
                    this.pollMsg();
                }).finally(() => this.msgLoading = false);
            },
            getMsgList() {
                let loading = this.loading, more = this.more;
                if(loading || !more) return;
                this.loading = true;
                let page = this.page, pageSize = this.pageSize;
                const url = `/admin/mini-ask-buddha?expand=miniUser,lastChat&page=${page}&pageSize=${pageSize}`;
                return this.$axios(url, {noTempleFilter: true}).then(res => {
                    const data = res.data;
                    if(page == 1) {
                        this.list = data;
                    } else {
                        this.list = this.list.concat(data);
                    }
                    if (page >= res.page.pageCount) {
                        this.more = false;
                    } else {
                        this.page += 1;
                    }
                    this.pollChat();
                }).finally(() => this.loading = false);
            },
            getTimeText (time) {
                let type = Object.prototype.toString.call(time);
                if(type !== '[object Date]') {
                    if(type === '[object String]') {
                        time = getDate(time);
                    } else {
                        time = new Date(time);
                    }
                }
                let text = "";
                let tis = time.getTime();
                let now = new Date();
                let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0,0);
                let tds = today.getTime();
                if(now.getTime() - tis <= 60 * 1000) {
                    text = "刚刚";
                } else if(tis >= tds) {
                    text = time.pattern("HH:mm");
                } else if(tis >= tds - 24 * 60 * 60 * 1000) {
                    text = "昨天";
                } else if (tis >= tds - 7 * 24 * 60 * 60 * 1000) {
                    text = time.pattern("EEE");
                } else {
                    text = time.pattern("yyyy-MM-dd");
                }
                return text;
            }
        }
    }
</script>

<style lang="less">
.buddha-reply {
  display: flex;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0;
}
.left-area {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.left-area {
  border-right: 1px solid #E8E8E8;
  background-color: #F8F3E7;
}
.right-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #DEDEDE;
  .area-ctx {
    margin-top: 0;
    padding: 10px 0;
    border-top: 1px solid #dcdfe6;
    background-color: #f7f7f7;
  }
  .area-ti {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
  }
}
.s-bar {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px;
  height: 41px;
  background-color: #FDFCF6;
  border-bottom: 1px solid #E8E8E8;
  font-size: 16px;
}
.msg-loading-text {
  margin-left: 6px;
}
.refresh-btn {
  position: absolute;
  top: 12px;
  right: 10px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 1;
  color: #FF8C00;
  &.refreshing {
    animation: loading 2s linear infinite;
  }
}
.s-input {
  height: 30px;
  line-height: 30px;
  -webkit-appearance: none;
  outline: none;
}
.el-icon-search {
  margin-right: 5px;
  font-size: 20px;
  color: #DCDFE6;
}
.area-ctx {
  flex: 1;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.area-ti {
  display: flex;
  align-items: center;
  background-color: #FDFCF6;
  font-size: 16px;
}
.sch-item {
  display: flex;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #FFF6E8;
  }
  &.active {
    background-color: #FFCE7E;
  }
}
.sch-img,
.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.avatar {
  border-radius: 100%;
}
.sch-info {
  flex: 1;
  padding: 0 8px;
  overflow: hidden;
}
.sch-name{
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sch-msg {
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.msg-time {
  color: @text-color-secondary;
}
.ti-logo {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
.area-input {
  padding: 10px;
  border-top: 1px solid #dcdfe6;
  background: #fff;
  textarea {
    resize: none;
    width: 100%;
    height: 80px;
    outline: none;
    border-radius: 0;
    border: 0;
    -webkit-appearance: none;
    color: inherit;
  }
}
.send {
  float: right;
  width: 80px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #409EFF;
    border: none;
    color: #fff;
  }
}

.msg-item {
  display: flex;
  margin-top: 20px;
  padding:  0 60px 0 10px;
  &.msg-right {
    transform: rotateY(180deg);
    color: #fff;
    .msg-detail {
      .msg-content,
      &:before {
        background-color: #FF8C00;
      }
    }
    .msg-avatar,
    .msg-content {
      transform: rotateY(180deg);
    }
  }
}

.msg-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.msg-detail {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    bottom: 0;
    left: -5px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
  }
}
.el-icon-warning,
.loading {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 24px;
  height: 24px;
  margin: auto 0;
}
.el-icon-warning {
  transform: translateX(100%);
  cursor: pointer;
  font-size: 24px;
  color: #F56C6C;
}
.loading {
  animation: loading-right 1s steps(12, end) infinite;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxMDB2MTAwSDB6Ii8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTlFOUU5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMwKSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iIzk4OTY5NyIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgzMCAxMDUuOTggNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjOUI5OTlBIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDYwIDc1Ljk4IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0EzQTFBMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCA2NSA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNBQkE5QUEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwIDU4LjY2IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0IyQjJCMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgxNTAgNTQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjQkFCOEI5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA1MCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDMkMwQzEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE1MCA0NS45OCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDQkNCQ0IiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEyMCA0MS4zNCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNEMkQyRDIiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDM1IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0RBREFEQSIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgtNjAgMjQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTJFMkUyIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKC0zMCAtNS45OCA2NSkiLz48L3N2Zz4=) no-repeat;
  background-size: 100%;
}

.msg-content {
  position: relative;
  padding: 5px 8px;
  background-color: #fff;
  border-radius: 2px;
  line-height: 1.5;
}
.msg-text-p {
  margin-bottom: 0;
}

.l-tip {
  padding: 10px 0;
  cursor: pointer;
  color: @primary-color;
  font-size: 12px;
  text-align: center;
  &.no-more {
    color: @text-color-secondary;
  }
}
@keyframes loading-right {
  0% {
    transform: translateX(100%) rotate(0deg);
  }
  100% {
    transform: translateX(100%) rotate(1turn);
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
