<template>
    <div class="content md">
        <system-admin type="hr" :privilege="privilege"></system-admin>
    </div>
</template>

<script>
    import SystemAdmin from "../../layouts/SystemAdmin";
    import { getSystemPermissionOptions } from '../../permission/subsystem';

    export default {
        name: "HRAdmin",
        components: {
            SystemAdmin
        },
        data() {
            return {
                privilege: [],
            }
        },
        created() {
            this.getPrivilege();
        },
        methods: {
            getPrivilege() {
                // key为1,2,14
                let options = getSystemPermissionOptions(2);
                let opt1 = options.slice(0, 2);
                let opt2 = options.slice(-1);
                let opts = [...opt1,...opt2];
                this.privilege = opts.map(item => item.key);
            },
        }
    }
</script>

<style scoped>

</style>
