<template>
  <div style="position: relative" :style="{lineHeight}">
    <div
      :style="{height: rowHeight, ...underlineStyle, marginLeft: i == 0 && indent ? indent : 0}"
      v-for="(p, i) in new Array(line || 0).fill(0)" :key="i"></div>
    <div style="position: absolute;top: 0;left: 0;width: 100%;">
      <slot></slot>
    </div>
  </div>
</template>

<script>

    export default {
        name: "MinRowText",
        props: {
            indent: String,
            underlineStyle: {
                type: Object,
                default() {
                    return {}
                }
            },
            line: Number,
            lineHeight: [String, Number]
        },
        computed: {
            rowHeight() {
                let res = 0;
                const lineHeight = this.lineHeight;
                if(lineHeight) {
                    if(isNaN(lineHeight * 1)) {
                        res = lineHeight;
                    } else {
                        res = lineHeight + "em";
                    }
                }
                return res;
            }
        }
    }
</script>

<style lang="less">

</style>
